import "core-js/stable";
import "regenerator-runtime/runtime";
import 'whatwg-fetch'

import Vue from 'vue/dist/vue.esm'
import VueApollo from 'vue-apollo'

import ApplicationForm from '../vue/components/ApplicationForm.vue'
import Countdown from '../vue/components/Countdown.vue'

import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import Vuelidate from 'vuelidate'

Vue.use(VueApollo)
Vue.use(Vuelidate)

const getCsrfToken = () => { return document.getElementsByName("csrf-token")[0].content }

const httpLink = createHttpLink({
  // You should use an absolute URL here
  uri: 'https://utstallningen-staging.ungaforskare.se/graphql',
    headers: {
    "Content-Type": "application/json",
    "X-CSRF-Token": getCsrfToken()
  },
  credentials: "include"
})

// Cache implementation
const cache = new InMemoryCache()

// Create the apollo client
const apolloClient = new ApolloClient({
  link: httpLink,
  cache,
})

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
})

document.addEventListener('turbolinks:load', () => {
  const app = new Vue({
    el: '[data-behavior="vue"]',
    apolloProvider,
    components: { ApplicationForm, Countdown }
  })
})